/**
 *
 * Loading
 *
 */
import React from 'react';
import styled from 'styled-components/macro';

interface Props {}

export function Loading(props: Props) {
  return <Image src="https://cdn.dribbble.com/users/31492/screenshots/3561297/untitled8.gif" alt="Loading..." />;
}

interface ImageProps {
  src: string;
  alt: string;
}

const Image = styled.img<ImageProps>`
  width: 500px;
  height: 500px;
`;
